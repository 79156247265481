import React from 'react'
import Select from 'react-select'

function CustomSelect({ fieldName, value = null, onBlur = null, onChange = null, options = null }) {
    const sampleOptions = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    const customStyles = {
        container: (provided, state) => ({ ...provided }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'blue',
            padding: 20,
        }),
        control: (provided, state) => ({
            ...provided,
            // none of react-select's styles are passed to <Control />
            borderRadius: 999,
            padding: "0px 30px",
            boxShadow: "inset 0px 0px 8px 4px #30a8db",
            border: "1px solid rgba(29, 46, 129, 0.3)",
            backgroundColor: "rgba(38, 13, 76, 0)",
            height: 50
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        },
        placeholder: (provided, state) => ({
            ...provided,
            fontSize: 14,
            color: "#fff",
            width: "100%"
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none"
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#311a83",
            borderRadius: 5,
            transition: "all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out",
        }),
        menuList: (provided, state) => ({
            ...provided,
            margin: 0,
            padding: 0,
            borderRadius: 5,
        }),
        option: (provided, state) => ({
            ...provided,
            padding: 0,
            paddingLeft: "18px",
            paddingRight: "29px",
            lineHeight: "40px",
            color: "#c4cbf9",
            transition: "all 0.2s",
            fontWeight: 400,
            lineHeight: "40px",
            listStyle: "none",
            minHeight: "40px",
            fontSize: 16,
            backgroundColor: state.isFocused ? "#211258" : "transparent",
            fontFamily: '"Josefin Sans", sans-serif'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#c4cbf9",
            fontSize: 16,
        }),
    }

    return (
        <Select
            isSearchable={false}
            options={options ? options : sampleOptions}
            styles={customStyles}
            placeholder="-- Please choose an option --"
            onBlur={onBlur}
            onChange={onChange}
            value={value ? options.find(option => option.value === value) : ''}
            name={fieldName}
        />
    )
}

export default CustomSelect;
